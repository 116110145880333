<template>
    <NuxtLayout name="default">
        <div class="flex flex-col w-full">
            <div class="flex justify-center max-w-md mx-auto flex-1">
                <slot />
            </div>

            <layout-footer />
        </div>
    </NuxtLayout>
</template>
